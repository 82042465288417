<template>
  <div id="app" class="container-fluid">
    <b-row>
      <b-col md="12">
        <top-menu class="mt-2 mb-2"/>
      </b-col>
      <b-col md="12">
        <div class="m-3">
          <router-view/>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import TopMenu from "@/components/TopMenu";
import {mapState} from 'vuex'

export default {
  name: 'App',
  components: {TopMenu},
  async created() {
    // if (!localStorage.getItem('token')) {
    //   if (this.$route.name !== 'login') {
    //     window.location = '/login'
    //   }
    // } else {
    //   this.$store.dispatch('auth/profile').catch(response => {
    //     if (response.response.status === 403) {
    //       localStorage.removeItem('token')
    //     }
    //   }).finally(() => {
    //     if (!localStorage.getItem('token') && this.$route.name !== 'login') {
    //       window.location = '/login'
    //     }
    //   })
    // }
  },
  computed: {
    ...mapState('auth', ['token'])
  }
}
</script>

<style>
@import 'c3/c3.min.css';

#app {
  overflow-x: hidden;
}

.content {
  height: calc(100vh - 20px);
  overflow-x: auto;
}

.pointer {
  cursor: pointer;
}

body.modal-open {
  overflow: inherit;
  padding-right: 0 !important;
}

#app {
  background-color: rgb(250, 200, 100);
}

* {
  font-family: "Electrolize", sans-serif
}

@font-face {
  font-family: "Electrolize";
  src: url(./assets/Electrolize-Regular.ttf) format("truetype");
}

.capitalize {
  text-transform: capitalize;
}
</style>
