import Vue from "vue";

export default {
    namespaced: true,
    state: {
        scrapPrices: [],
        products: []
    },
    actions: {
        scrapPrices({commit}, search) {
            return Vue.axios.get(`/scrap/${search}/prices`).then((response) => {
                commit("setScrapPrices", response.data.data);
            });
        },
        productList({commit}) {
            return Vue.axios.get(`/product`).then((response) => {
                commit("setProducts", response.data.data);
            });
        }
    },
    mutations: {
        setScrapPrices(state, scrapPrices) {
            state.scrapPrices = scrapPrices;
        },
        setProducts(state, products) {
            state.products = products;
        },
    },
};
