<template>
  <div>
    <b-row class="header-content">
      <b-col md="12">
        <div class="header-wrapper">
          <img alt="app logo" class="bg-light pl-3 pr-3 p-2 logo float-left" src="../assets/logo.png">
          <p>Transistor<span class="d-none d-sm-inline"> - Argus informatique communautaire</span></p>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <div class="m-3">
          <b-row class="top-menu-wrapper p-2 text-light bg-dark">
            <b-col md="7">
              <span class="float-left category-item pt-1 mr-4 pointer" @click="searchByType()">
                Tout
              </span>
              <span class="float-left category-item pt-1 mr-4 pointer" @click="searchByType('cpu')">
                Processeurs
              </span>
              <span class="float-left category-item pt-1 pointer" @click="searchByType('gpu')">
                Cartes graphiques
              </span>
            </b-col>
            <b-col md="3">
              <b-input class="float-right search-bar" placeholder="🔎 Composant ..." type="text"
                       @input="onComponentSearch"></b-input>
            </b-col>
            <b-col class="text-center pt-2" md="2">
              Mon compte
            </b-col>
          </b-row>
          <b-row>
            <b-col class="bg-light p-2 top-menu-sections pl-4">
              <span class="float-left category-item mr-4">Les tops composants</span>
              <span class="float-left category-item mr-4">Nouveautés</span>
              <span class="float-left category-item">Les plus vus</span>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  components: {},
  methods: {
    logout() {
      this.$store.dispatch('auth/logout')
    },
    onComponentSearch(search) {
      this.$store.dispatch('components/search', {
        search,
        options: {params: {component_type: this.$route.query.component_type}}
      })
    },
    searchByType(component_type) {
      this.$router.push({query:{component_type}})
      this.$store.dispatch('components/list', {params: {component_type}})
    }
  },
  computed: {
    ...mapState('auth', ['user'])
  }

};
</script>

<style scoped>
.logo {
  width: 75px;
  border-radius: 5px;
}

.header-wrapper {
  text-align: center;
}

@media (min-width: 576px) {
  .header-content {
    margin-left: calc(50% - 350px);
    width: 700px;
    font-size: 1.6em;
  }
}

@media (max-width: 576px) {
  .header-content {
    font-size: 2em;
  }
}

p {
  padding-top: 10px;
}

h3 {
  padding-top: 20px;
}

.category-item {
  font-size: 1.2em;
}

.top-menu-wrapper {
  border-radius: 20% 20% 0 0;
}

.top-menu-sections {
  border-radius: 0 0 20% 20%;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
}

</style>
