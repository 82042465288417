import HomePage from "./components/HomePage.vue";
import ScrapPrices from "./components/ScrapPrices.vue";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

export default new VueRouter({
  mode: "history",
  base: "/",
  routes: [
    { name: "home", path: "/", component: HomePage },
    { name: "comparator", path: "/comparator", component: ScrapPrices },
  ],
});
