<template>
  <b-card>
    <b-card-header>Amazon prices by brand</b-card-header>
    <b-card-body>
      <b-select label-field="name" v-model="selectedProduct" :options="options" @change="onProductSelect"></b-select>
      <hr/>
      <div id="chart"></div>
    </b-card-body>
  </b-card>
</template>

<script>
import {mapState} from 'vuex'
import c3 from 'c3';

export default {
  data() {
    return {
      chart: undefined,
      selectedProduct: undefined,
    }
  },
  components: {},
  methods: {
    removeChart() {
      this.chart && this.chart.destroy()
    },
    onProductSelect(){
      this.refreshChart(this.selectedProduct.name)
    },
    refreshChart(productName) {
      console.log('on refresh chart', productName)
      this.$store.dispatch('scrap/scrapPrices', productName).then(() => {
        this.removeChart()
        this.chart = c3.generate({
          bindto: '#chart',
          data: {
            x: 'x',
            columns: this.scrapPrices
          },
          axis: {
            x: {
              type: 'timeseries',
              tick: {
                format: '%Y-%m-%d'
              }
            }
          }
        });
      })

    }
  },
  computed: {
    ...mapState('scrap', ['scrapPrices', 'products']),
    options() {
      return this.products.map(product => {
        return {text: product.name, value: product}
      })
    }
  },
  mounted() {
    this.$store.dispatch('scrap/productList').then(()=> {
      this.selectedProduct = this.products[0]
      this.refreshChart(this.products[0].name)
    })
  },

  destroyed() {
    this.removeChart()
  }

};
</script>

