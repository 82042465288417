<template>
  <b-row>
    <b-col md="12">
      <b-card class="text-center" header="Actualités PC">
        <b-img fluid src="https://upload.wikimedia.org/wikipedia/commons/d/d9/Rss-feed.svg"/>
      </b-card>
    </b-col>
    <b-col class="mt-4" md="12">
      <b-card class="text-center" header="Chat communauté">
        <b-img fluid src="https://www.svgrepo.com/show/2259/dialogue.svg"/>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>

export default {};
</script>

<style scoped>


</style>