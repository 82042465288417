<template>
  <div>
    <h5 class="mb-1 text-dark">GPU</h5>
    <b-card class="mb-4 p-2 card-wrapper bg-light">
      <b-row>
        <b-col md="2">
          <b-img class="pt-4 mb-2"
                 fluid
                 src="https://static.utopland.net/2070.webp"></b-img>
        </b-col>
        <b-col md="10">
          <b-row class="text-center">
            <b-col md="6">
              <div class="bg-dark text-light ml-1 mb-1 component-spec component-name p-1">
                <span class="capitalize">{{ component.brand }}</span>
                <br/>
                {{ component.search }}
              </div>
            </b-col>
            <b-col md="3">
              <div class="bg-dark text-light pt-2 ml-1 mb-1 component-spec">Neuf<br/> {{ parseInt(component.price) }} €
              </div>
            </b-col>
            <b-col md="3">
              <div class="bg-dark text-light pt-2 ml-1 mb-1 component-spec"><b>Occasion<br/> {{ priceRefurbish }} €</b>
              </div>
            </b-col>
            <b-col md="12">
              <b-form-input v-model="estimatedPrice" :max="priceMax" :min="priceMin" class="mt-3" type="range"/>
              <b-row>
                <b-col class="text-left" cols="4" md="3"><b>{{ priceMin }} €</b></b-col>
                <b-col class="text-center" cols="4" md="6"><b>{{ estimatedPrice }} €</b></b-col>
                <b-col class="text-right" cols="4" md="3"><b>{{ priceMax }} €</b></b-col>
                <b-col class="pt-md-2 mt-md-2 text-light" cols="12" md="12">
                  <b-button class="adjust-price mt-2">Ajuster le prix d'occasion
                    <check-outline/>
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import CheckOutline from 'vue-material-design-icons/CheckOutline.vue';

export default {
  components: {CheckOutline},
  props: {
    component: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      estimatedPrice: undefined,
      priceRefurbish: undefined
    }
  },
  created() {
    this.priceRefurbish = parseInt((this.component.price * 0.7) + (Math.random() > 0.5 ? -1 : 1) * (Math.random() * (this.component.price * 0.03)))
    this.estimatedPrice = this.priceRefurbish
  },
  computed: {
    priceMin() {
      return parseInt((this.component.price * 0.7) - (this.component.price * 0.05) - (Math.random() * (this.component.price * 0.05)))
    },
    priceMax() {
      return parseInt((this.component.price * 0.7) + (this.component.price * 0.05) + (Math.random() * (this.component.price * 0.05)))
    },
  }
};
</script>

<style scoped>
.component-spec {
  border-radius: 10px;
  height: 70px;
}

.component-name {
  font-size: 1.2em;
}

.adjust-price {
  background-color: #53978E;
}

.price-adjust-cell-validation {
  border-radius: 8px;
  font-size: 1.3em;
  height: 50px;
  width: 50px;
  background-color: #53978E;

}

.card-wrapper {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%)
}

</style>