import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";
import { debounce } from "throttle-debounce";

export default (callback, store) => {
  const stopLoad = debounce(500, () => {
    store.commit("core/setLoading", false);
  });
  const instance = axios.create({
    timeout: 15000,
    headers: {
      "Content-Type": "application/json",
    },
  });
  instance.interceptors.request.use(async (config) => {
    const api_key = localStorage.getItem("token");
    if (api_key) {
      config.headers = { api_key };
    }
    store.commit("core/setLoading", true);
    return config;
  });
  instance.interceptors.response.use((response) => {
    stopLoad();
    return response;
  });
  Vue.use(VueAxios, instance);
  Vue.axios.defaults.baseURL = (process.env.VUE_APP_TRANSISTOR_API || "") + "/v1/";
  console.log("Base api url", Vue.axios.defaults.baseURL);
  callback();
};

export const apiRoot = `${process.env.VUE_APP_TRANSISTOR_API}/api/v1/`;
