import Vue from "vue";
import Vuex from "vuex";
import core from "./core";
import settings from "./settings";
import auth from "./auth";
import scrap from "./scrap";
import components from "./components";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        core,
        auth,
        settings,
        scrap,
        components,
    },
});
