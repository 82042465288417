<template>
  <b-row>
    <b-col md="9">
      <component-card v-for="component in components" :key="component.id" :component="component"/>
    </b-col>
    <b-col md="3">
      <community-content/>
    </b-col>
  </b-row>
</template>

<script>
import {mapState} from "vuex";
import CommunityContent from '@/components/CommunityContent.vue'
import ComponentCard from '@/components/ComponentCard.vue'

export default {
  components: {CommunityContent, ComponentCard},
  created() {
    this.$store.dispatch("components/list", {params: {component_type: this.$route.query.ComponentType}})
  },
  computed: {
    ...mapState("components", ["components"])
  },
};
</script>

<style scoped>
.component-spec {
  font-size: 1.2em;
  border-radius: 10px;
  height: 70px;
}

.adjust-price {
  background-color: #0a435a;
  border-radius: 8px;
}

.price-adjust-cell {
  border: 2px solid #777;
  border-radius: 8px;
  font-size: 1.3em;
  height: 50px;

}

.price-adjust-cell-validation {
  border: 2px solid #777;
  font-size: 1.3em;
  height: 50px;
  width: 50px;
}

</style>