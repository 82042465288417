import Vue from "vue";

export default {
  namespaced: true,
  state: {
    user: undefined,
  },
  actions: {
    profile({ commit }) {
      return Vue.axios.get("/user/profile").then((data) => {
        commit("setUser", data.data.user);
      });
    },
    login(_, form) {
      return Vue.axios.post("/user/login", form).then((data) => {
        localStorage.setItem("token", data.data.token);
        window.location = "/";
      });
    },
    logout({ commit }) {
      commit("setUser", undefined);
      localStorage.removeItem("token");
      return Vue.axios.post("/user/logout").finally(() => {
        window.location = "/login";
      });
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
  },
  getters: {},
};
