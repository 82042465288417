import Vue from "vue";

export default {
    namespaced: true,
    state: {
        components: [],
    },
    actions: {
        list({commit}, options) {
            return Vue.axios.get(`/component`, options).then((response) => {
                commit("setComponents", response.data.data);
            });
        },
        search({commit}, {search, options}){
            return Vue.axios.get(`/component/search/${search}`, options).then((response) => {
            console.log('search', response.data.data)
                commit("setComponents", response.data.data);
            });

        }
    },
    mutations: {
        setComponents(state, components) {
            console.log('on set')
            state.components = components;
        },
    },
};
